.icon-16-drop-down {
  background: none;
}

.DateInput_input {
  font-size: 1em !important;
  font-family: 'Inter';
}

.MuiFormLabel-root {
  font-size: 13px !important;
  font-family: 'Inter';
}

.MuiInputBase-root {
  font-size: 13px !important;
  font-family: 'Inter';
}

.MuiOutlinedInput-root {
  border-radius: 8px !important;
  font-family: 'Inter';
}

.MuiSelect-root {
  font-size: 14px !important;
  font-family: 'Inter';
}

.MuiListItem-root {
  font-size: 14px !important;
  font-family: 'Inter';
}

.store-hardware-content a {
  margin-bottom: 8px;
}

.store-hardware-content a:last-child {
  margin-bottom: 0px;
}

.pop-box-content::-webkit-scrollbar {
  display: none;
}

.store-header .campaing-card-left .store-id,
.card-header .card-header-left .store-id {
  color: #AEB2C3;
  margin-left: 16px;
  margin-top: 7px;
}

.campaing-tab-content .card-content{
  margin-top: 10px;
}