.radial-chart {
  position: relative;
  display: inline-block;
  transition: all 0.3s ease-in;
}
.radial-chart.no-progress .radial-chart-progress {
  opacity: 0;
}
.radial-chart-progress {
  transform: rotate(-90deg);
  transform-origin: center;
  transition: all 0.6s cubic-bezier(0.58, 0.16, 0.5, 1.14);
  transition-delay: 0.3s; 
}
.radial-chart-label {
	font-family: 'Ojmar Sans', sanf-serif;
	font-size: 14px;
	font-weight: 700;
}
